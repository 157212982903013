
.logo-wrapper img {

   /* // Origianl Crypto Sports Star Logo size 
    width: 135px;
    margin-bottom: -1px;
    float: left;
 */
    width: 80px;
    margin-bottom: -2px;
    float: left;

  }
  
.logo-wrapper img {
    justify-content: left;
    padding-right: 3%
  
}